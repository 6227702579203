export class Roles {
  public static readonly AdministratorRole: string = "1";
  public static readonly CustomerRole: string = "2";
  public static readonly DriverRole: string = "3";

  public static readonly DashboardViewRole: string = "11";
  public static readonly ReportViewRole: string = "12";
  public static readonly UserViewRole: string = "13";
  public static readonly UserManageRole: string = "14";
  public static readonly RoleViewRole: string = "15";
  public static readonly RoleManageRole: string = "16";
  public static readonly SupplierViewRole: string = "17";
  public static readonly SupplierManageRole: string = "18";
  public static readonly PurchaseOrderViewRole: string = "19";
  public static readonly PurchaseOrderManageRole: string = "20";
  public static readonly WarehouseViewRole: string = "21";
  public static readonly WarehouseManageRole: string = "22";
  public static readonly CityViewRole: string = "23";
  public static readonly CityManageRole: string = "24";
  public static readonly CategoryViewRole: string = "25";
  public static readonly CategoryManageRole: string = "26";
  public static readonly TagViewRole: string = "27";
  public static readonly TagManageRole: string = "28";
  public static readonly ItemViewRole: string = "29";
  public static readonly ItemManageRole: string = "30";
  public static readonly ItemStockManageRole: string = "31";
  public static readonly CouponViewRole: string = "32";
  public static readonly CouponManageRole: string = "33";
  public static readonly BannerViewRole: string = "34";
  public static readonly BannerManageRole: string = "35";
  public static readonly OrderViewRole: string = "36";
  public static readonly OrderManageRole: string = "37";
  public static readonly TimeSlotManageRole: string = "38";
  public static readonly CustomerViewRole: string = "39";
  public static readonly CustomerManageRole: string = "40";
  public static readonly BynowRole: string = "41";
  public static readonly MapViewRole: string = "42";
  public static readonly DeliveryRole: string = "43";
  public static readonly AdministratorOrderViewRole: string = "44";
  public static readonly ProcurementOrderViewRole: string = "45";
  public static readonly LogisticsOrderViewRole: string = "46";
  public static readonly SalesOrderViewRole: string = "47";
  public static readonly NotificationViewRole: string = "48";
  public static readonly JahezRole: string = "49";
  public static readonly ManagePendingRequests: string = "50";
  public static readonly ViewAspfTransations: string = "51";
  public static readonly ManageAspf: string = "52";
  public static readonly ManageJahezCustomers: string = "53";
  public static readonly SEOManageItems: string = "54";
  public static readonly SEOManageCategories: string = "55";
  public static readonly SEOManageBlogs: string = "56";
  public static readonly LogisticsViewItems: string = "57";
  public static readonly LogisticsUpdateItem: string = "58";
  public static readonly LogisticsUpdateStock: string = "59";
  public static readonly LogisticsViewTransactions: string = "60";
  public static readonly BrandViewRole: string = "61";
  public static readonly BrandCreateRole: string = "62";
  public static readonly ManageVerifyPendingRequests: string = "63";
  public static readonly ItemsViewTransactions: string = "64";
  public static readonly ViewJahezCustomers: string = "66";
  public static readonly ViewPurchaseOrderReport: string = "65";
  public static readonly OfflineOrdersViewRole: string = "67";
  public static readonly OfflineOrdersManageRole: string = "68";
  public static readonly WarehouseItemQtyViewRole: string = "69";
  public static readonly CataloguesManageRole: string = "70";
  public static readonly ManageRedirections: string = "71";
  public static readonly ManageCSP: string = "72";
  public static readonly ViewCSP: string = "73";
  public static readonly CustomerManageNames: string = "75";

  public static readonly ViewDriverAssingmentPoReport: string = "76";
  public static readonly ViewDriverAssingmentOrderReport: string = "77";
  public static readonly ViewVerifyRegistrationRequests: string = "78";
  public static readonly ViewApprovalRegistrationRequests: string = "79";
}

export const AuthsSelect = [
  {
    label: "Dashboard",
    data: [{ label: "View Dashboard", value: Roles.DashboardViewRole }],
  },
  {
    label: "Reports",
    data: [
      { label: "View Reports", value: Roles.ReportViewRole },
      {
        label: "View Purchase Order Reports",
        value: Roles.ViewPurchaseOrderReport,
      },
      {
        label: "View Derivers Order Report",
        value: Roles.ViewDriverAssingmentOrderReport,
      },
      {
        label: "View PO Count Report",
        value: Roles.ViewDriverAssingmentPoReport,
      },
    ],
  },
  {
    label: "Users & Roles",
    data: [
      { label: "View Users", value: Roles.UserViewRole },
      { label: "Manage Users", value: Roles.UserManageRole },
      { label: "View Roles", value: Roles.RoleViewRole },
      { label: "Manage Roles", value: Roles.RoleManageRole },
    ],
  },
  {
    label: "Suppliers & Purchase Orders",
    data: [
      { label: "View Suppliers", value: Roles.SupplierViewRole },
      { label: "Manage Suppliers", value: Roles.SupplierManageRole },
      { label: "View Purchase Orders", value: Roles.PurchaseOrderViewRole },
      { label: "Manage Purchase Orders", value: Roles.PurchaseOrderManageRole },
    ],
  },
  {
    label: "Warehouses & Cities",
    data: [
      { label: "View Warehouses", value: Roles.WarehouseViewRole },
      { label: "Manage Warehouses", value: Roles.WarehouseManageRole },
      { label: "View Cities", value: Roles.CityViewRole },
      { label: "Manage Cities", value: Roles.CityManageRole },
      { label: "Logistic View Items", value: Roles.LogisticsViewItems },
      { label: "Logistic Update Item", value: Roles.LogisticsUpdateItem },
      { label: "Logistic Update Stock", value: Roles.LogisticsUpdateStock },
      {
        label: "Logistic View Transactions",
        value: Roles.LogisticsViewTransactions,
      },
      { label: "Item Quantities View", value: Roles.WarehouseItemQtyViewRole },
    ],
  },
  {
    label: "Categories & Tags",
    data: [
      { label: "View Categories", value: Roles.CategoryViewRole },
      { label: "Manage Catergories", value: Roles.CategoryManageRole },
      { label: "View Tags", value: Roles.TagViewRole },
      { label: "Manage Tags", value: Roles.TagManageRole },
    ],
  },
  {
    label: "Items & Stock",
    data: [
      { label: "View Items", value: Roles.ItemViewRole },
      { label: "Manage Items", value: Roles.ItemManageRole },
      { label: "View Items Transactions", value: Roles.ItemsViewTransactions },
      { label: "View Tags", value: Roles.TagViewRole },
      { label: "Manage Tags", value: Roles.TagManageRole },
    ],
  },
  {
    label: "Brand",
    data: [
      { label: "View Brands", value: Roles.BrandViewRole },
      { label: "Create Brand", value: Roles.BrandCreateRole },
    ],
  },
  {
    label: "Coupons",
    data: [
      { label: "View Coupons", value: Roles.CouponViewRole },
      { label: "Manage Coupons", value: Roles.CouponManageRole },
    ],
  },
  {
    label: "Banners",
    data: [
      { label: "View Banners", value: Roles.BannerViewRole },
      { label: "Manage Banners", value: Roles.BannerManageRole },
    ],
  },
  {
    label: "Orders & Time Slots",
    data: [
      { label: "View Orders", value: Roles.OrderViewRole },
      { label: "Manage Orders", value: Roles.OrderManageRole },
      { label: "Manage Time Slots", value: Roles.TimeSlotManageRole },
    ],
  },
  {
    label: "Offline Orders",
    data: [
      { label: "View Orders", value: Roles.OfflineOrdersViewRole },
      { label: "Manage Orders", value: Roles.OfflineOrdersManageRole },
    ],
  },
  {
    label: "Customers",
    data: [
      { label: "View Customers", value: Roles.CustomerViewRole },
      { label: "Manage Customers", value: Roles.CustomerManageRole },
      {
        label: "Manage Customer Brand & Contact Names",
        value: Roles.CustomerManageNames,
      },
    ],
  },
  {
    label: "Delivery & Map",
    data: [
      { label: "Delivery App", value: Roles.DeliveryRole },
      { label: "View All Addresses", value: Roles.MapViewRole },
    ],
  },
  {
    label: "Order View",
    data: [
      { label: "Administrator Role", value: Roles.AdministratorOrderViewRole },
      { label: "Procurement Role", value: Roles.ProcurementOrderViewRole },
      { label: "Logistics Role", value: Roles.LogisticsOrderViewRole },
      { label: "Sales Role", value: Roles.SalesOrderViewRole },
    ],
  },
  {
    label: "Notification",
    data: [
      { label: "Notification View Role", value: Roles.NotificationViewRole },
    ],
  },
  {
    label: "Jahez",
    data: [
      {
        label: "Manage Verification Required Requests",
        value: Roles.ManageVerifyPendingRequests,
      },
      {
        label: "View Verification Required Requests",
        value: Roles.ViewVerifyRegistrationRequests,
      },
      { label: "Manage Pending Requests", value: Roles.ManagePendingRequests },
      {
        label: "View Pending Requests",
        value: Roles.ViewApprovalRegistrationRequests,
      },
      { label: "View ASPF Transactions", value: Roles.ViewAspfTransations },
      { label: "Manage ASPF", value: Roles.ManageAspf },
      { label: "Manage Jahez Customers", value: Roles.ManageJahezCustomers },
      { label: "View Jahez Customers", value: Roles.ViewJahezCustomers },
    ],
  },
  {
    label: "SEO",
    data: [
      { label: "Manage Items", value: Roles.SEOManageItems },
      { label: "Manage Catergories", value: Roles.SEOManageCategories },
      { label: "Manage Blogs", value: Roles.SEOManageBlogs },
    ],
  },
  {
    label: "Catalogue",
    data: [{ label: "Manage Catalogue", value: Roles.CataloguesManageRole }],
  },
  {
    label: "Redirections",
    data: [{ label: "Manage Redirections", value: Roles.ManageRedirections }],
  },
  {
    label: "Customer Special Price",
    data: [
      { label: "View Special Price", value: Roles.ViewCSP },
      { label: "Manage Special Price", value: Roles.ManageCSP },
    ],
  },
];
