import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("./containers/home/home.routes").then((m) => m.HOME_ROUTES),
    data: {
      title: `Home`,
    },
  },
  {
    path: "orders",
    loadChildren: () =>
      import("./containers/orders/orders.routes").then((m) => m.Orders_ROUTES),
    data: {
      title: `Orders`,
    },
  },
  {
    path: "sales",
    loadChildren: () =>
      import("./containers/sales/sales.routes").then((m) => m.Sales_ROUTES),
    data: {
      title: `Sales`,
    },
  },
  {
    path: "items",
    loadChildren: () =>
      import("./containers/items/items.routes").then((m) => m.Items_ROUTES),
    data: {
      title: `Items`,
    },
  },
  {
    path: "user-administartion",
    loadChildren: () =>
      import(
        "./containers/user-administration/user-administration.routes"
      ).then((m) => m.User_Administration_ROUTES),
    data: {
      title: `User Adnministration`,
    },
  },
  {
    path: "customers",
    loadChildren: () =>
      import("./containers/customers/customers.routes").then(
        (m) => m.Customers_ROUTES
      ),
    data: {
      title: `Customers`,
    },
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./containers/reports/reports.routes").then(
        (m) => m.Reports_ROUTES
      ),
    data: {
      title: `Reports`,
    },
  },

  {
    path: "item-specifications",
    loadChildren: () =>
      import(
        "./containers/item-specifications/item-specifications.routes"
      ).then((m) => m.Item_Specifications_ROUTES),
    data: {
      title: `Item Specifications`,
    },
  },
  {
    path: "jahez",
    loadChildren: () =>
      import("./containers/jahez/jahez.routes").then((m) => m.Jahez_ROUTES),
    data: {
      title: `Jahez`,
    },
  },
  {
    path: "404",
    loadComponent: () =>
      import("./containers/home/404/404.component").then(
        (m) => m.NotFoundComponent
      ),
    data: {
      title: `Not Found`,
    },
  },
  {
    path: "401",
    loadComponent: () =>
      import("./containers/home/unauthorized/unauthorized.component").then(
        (m) => m.UnauthorizedComponent
      ),
    data: {
      title: `Not Found`,
    },
  },
  {
    path: "login",
    loadComponent: () =>
      import("./app.component").then(
        (m) => m.AppComponent
      ),
    data: {
      title: `Login`,
    },
  },
  {
    path: "",
    redirectTo: "/home/index",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
