import { CommonModule, NgFor } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import {
  InputGroupComponent,
  InputGroupTextDirective,
  FormControlDirective,
  FormFeedbackComponent,
} from "@coreui/angular-pro";
import {
  InputOptions,
  InputType,
  InputValue,
  InternalInputOptions,
  NumberedInputType,
  defaultInputSettings,
} from "./input.model";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { CheckboxComponent } from "../checkbox/checkbox.component";
import { makeErrorMessage } from "../../../shared/utils/form/form.util";
import { ButtonComponent } from "../button/button.component";
import { iconSubset } from "../../../../assets/icons/icon-subset";
import { IconDirective } from "@coreui/icons-angular";
import { ValidatorTuple } from "../../../shared/utils/form/validators";
import CustomFormControl from "../../../shared/utils/form/CustomFormControl";
import { overrideObj } from "../../../shared/utils/object.util";
import { BlockInvalidInputDirective } from "./block-invalid-input.directive";
import { RouterLink } from "@angular/router";

@Component({
  selector: "sol-input",
  standalone: true,
  imports: [
    InputGroupTextDirective,
    CommonModule,
    InputGroupComponent,
    InputGroupTextDirective,
    FormControlDirective,
    ReactiveFormsModule,
    FormFeedbackComponent,
    NgFor,
    CheckboxComponent,
    ButtonComponent,
    IconDirective,
    BlockInvalidInputDirective,
    RouterLink,
  ],
  templateUrl: "./input.component.html",
  styleUrl: "./input.component.scss",
})
export class InputComponent<T extends InputValue> extends CustomFormControl<
  InputOptions,
  InternalInputOptions
> {
  @Input() type: InputType = "text";
  @Input() value: T;
  // @Input() list?: string = "";
  list = input("");
  // @Input() options?: InputOptions;
  @Output() onChange = new EventEmitter<T>();
  // @Input() control?: FormControl;
  @Input() acceptedFileTypes?: string;
  @Input() multiFiles?: boolean;
  @ViewChild("fileInput", { static: false, read: ElementRef<HTMLInputElement> })
  fileInput: ElementRef<HTMLInputElement>;
  defaultSettings = defaultInputSettings;
  dynamicType = signal<string>("password");

  iconSubset = iconSubset;
  override ngOnInit(): void {
    this.defaultSettings = overrideObj(
      {
        ...this.defaultSettings,
      },
      { ...this.defaultSettings.types[this.type] }
    );
    super.ngOnInit();
    if (this.control) {
      if (
        this.internalOptions.min !== null &&
        this.internalOptions.min !== undefined
      ) {
        this.control.addValidators(Validators.min(this.internalOptions.min));
        this.control.updateValueAndValidity();
      }
      if (
        this.internalOptions.max !== null &&
        this.internalOptions.max !== undefined
      ) {
        this.control.addValidators(Validators.max(this.internalOptions.max));
        this.control.updateValueAndValidity();
      }
    }
  }

  changeType() {
    this.dynamicType() === "password"
      ? this.dynamicType.set("text")
      : this.dynamicType.set("password");
  }

  displayNumber(value: T) {
    if (value === null || value === undefined) return;

    switch (this.type) {
      case "price":
        if (!this.options) return value;
        if (
          this.options.decimalPoints &&
          (this.options.withDecimals === undefined ||
            this.options.withDecimals === true)
        )
          return (<number>value).toFixed(this.options.decimalPoints);
        if (!this.options.decimalPoints && this.options.withDecimals)
          return (<number>value).toFixed(2);
        return value;
    }

    return value;
  }

  updateValue(event: any) {
    const value = event.target.value as T;
    this.onChange.emit(value);
  }

  handleNumberPasteInputKeys(
    event: ClipboardEvent,
    type: NumberedInputType = "number"
  ) {}

  handleNumberInputKeys(
    event: KeyboardEvent,
    type: NumberedInputType = "number"
  ) {
    const invalidKeys = ["+", "e"];
    //default overall settings
    let allowDecimals: boolean = this.internalOptions.allowDecimals!,
      allowNegative: boolean = defaultInputSettings.allowNegative;

    switch (type) {
      case "percentage":
        allowDecimals = this.internalOptions.types!.number!.allowDecimals!;

        break;
      case "price":
        allowDecimals = this.internalOptions.types!.price!.allowDecimals!;
        break;
      case "numberedText":
        allowNegative =
          defaultInputSettings.types!.numberedText!.allowNegative!;
        allowDecimals =
          defaultInputSettings.types!.numberedText!.allowDecimals!;
        break;
      case "number":
      default:
        allowNegative = defaultInputSettings.types!.number!.allowNegative!;
        allowDecimals = defaultInputSettings.types!.number!.allowDecimals!;
    }

    if (this.options && this.options.allowDecimals !== undefined) {
      allowDecimals = this.options.allowDecimals;
    }

    if (this.options && this.options.allowNegative !== undefined) {
      allowNegative = this.options.allowNegative;
    }

    if (!allowNegative) invalidKeys.push("-");
    if (!allowDecimals) invalidKeys.push(".");
    let afterTheDot = true;
    if (allowDecimals) {
      if (this.internalOptions.decimalPoints) {
        const decimalPoints = this.internalOptions.decimalPoints;
        const value = this.control?.value ?? this.value;
        const valueAfterTheDot = value?.toString().split(".")[1];
        if (valueAfterTheDot?.length >= decimalPoints) {
          afterTheDot = Number.isInteger(Number(event.key)) ? false : true;
        }
      }
    }
    let nemberedTextCheck = true;
    if (type === "numberedText") {
      if (
        !(event.ctrlKey || event.metaKey) &&
        event.key.length === 1 &&
        Number.isNaN(Number(event.key))
      ) {
        nemberedTextCheck = false;
      }
    }

    return !invalidKeys.includes(event.key) && afterTheDot && nemberedTextCheck;
  }
  handleBlockInputKeys(
    type: NumberedInputType = "number"
  ): (value: string | number, key: string) => boolean {
    const func: (value: string | number, key: string) => boolean = (
      value: string | number,
      key: string
    ) => {
      const invalidKeys = ["+", "e", "E"];

      //default overall settings
      let allowDecimals: boolean = this.internalOptions.allowDecimals!,
        allowNegative: boolean = defaultInputSettings.allowNegative;

      switch (type) {
        case "percentage":
          allowDecimals = this.internalOptions.types!.number!.allowDecimals!;

          break;
        case "price":
          allowDecimals = this.internalOptions.types!.price!.allowDecimals!;
          break;
        case "numberedText":
          allowNegative =
            defaultInputSettings.types!.numberedText!.allowNegative!;
          allowDecimals =
            defaultInputSettings.types!.numberedText!.allowDecimals!;
          break;
        case "number":
        default:
          allowNegative = defaultInputSettings.types!.number!.allowNegative!;
          allowDecimals = defaultInputSettings.types!.number!.allowDecimals!;
      }

      if (this.options && this.options.allowDecimals !== undefined) {
        allowDecimals = this.options.allowDecimals;
      }

      if (this.options && this.options.allowNegative !== undefined) {
        allowNegative = this.options.allowNegative;
      }

      if (!allowNegative) invalidKeys.push("-");
      if (!allowDecimals) invalidKeys.push(".");
      if (invalidKeys.some((char) => key.toString().includes(char))) {
        return false;
      }
      if (
        this.options?.min !== undefined &&
        this.options?.min !== null &&
        !Number.isNaN(Number(value)) &&
        Number(value) < this.options.min
      ) {
        return false;
      }
      if (
        this.options?.max !== undefined &&
        this.options?.max !== null &&
        !Number.isNaN(Number(value)) &&
        Number(value) > this.options.max
      ) {
        return false;
      }
      if (allowDecimals) {
        if (this.internalOptions.decimalPoints) {
          const decimalPoints = this.internalOptions.decimalPoints;
          const valueAfterTheDot = value?.toString().split(".")[1];
          if (valueAfterTheDot?.length > decimalPoints) {
            return false;
          }
        }
      }
      if (type === "numberedText") {
        if (Number.isNaN(Number(value))) {
          return false;
        }
      }

      return !invalidKeys.some((key) => value.toString().includes(key));
    };
    return func;
  }
  // get controlErrors() {
  //   return Object.entries(this.control?.errors || {});
  // }

  // displayError(error: ValidatorTuple) {
  //   return makeErrorMessage(error, this.options?.label);
  // }
}
