<c-form-check
  [class]="{
	'flex-column-reverse' : direction === 'vertical',
	'fixed-hight' : direction === 'vertical',
	'justify-content-around' : direction === 'vertical',
	'd-flex' : direction === 'vertical',
	}"
>
  <input
    cFormCheckInput
    cFormControl
    (id)="(label)"
    type="checkbox"
    [formControl]="$any(control)"
    [checked]="isChecked"
    (change)="updateValue($event)"
    [class]="{
		'align-self-center' : direction === 'vertical',


		}"
  />
  <label
    cFormCheckLabel
    for="checkOne"
    [class]="{
		'align-self-start' : direction === 'vertical',

	}"
    [style]="labelStyle"
    >{{ label }}</label
  >
</c-form-check>
