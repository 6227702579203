import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, input } from '@angular/core';

@Directive({
  selector: '[solBlockInvalidInput]',
  standalone: true
})
export class BlockInvalidInputDirective {

   /**
   * A validation function that can handle different value types.
   */
  validateInput = input.required<(value: string | number, key: string) => boolean>({alias: 'solBlockInvalidInput'});

  private lastValidValue: string | number = '';
  private cursorPosition: number = 0;
  constructor(@Inject(DOCUMENT) private document: Document){}

  @HostListener('beforeinput', ['$event'])
  onBeforeInput(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;

    const currentValue = inputElement.value;
    const inputData = event.data ?? '';

    const selection = this.document.getSelection()?.toString() ?? '';

    // Compute the new value based on the drop and selection
    const newValue = this.computeNewValue(currentValue, inputData, selection);

    if (!this.validateInput()(newValue, inputData)) {
      event.preventDefault(); // Prevent invalid input
    } else {
      this.lastValidValue = newValue; // Update the last valid value
    }
  }


  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const inputElement = event.target as HTMLInputElement;

    const pastedText = event.clipboardData?.getData('text') || '';
    const currentValue = inputElement.value;
    const selection = this.document.getSelection()?.toString() ?? '';

    // Compute the new value based on the drop and selection
    const newValue = this.computeNewValue(currentValue, pastedText, selection);

    if (!this.validateInput()(newValue, pastedText)) {
      event.preventDefault(); // Prevent invalid paste
    } else {
      this.lastValidValue = newValue; // Update the last valid value
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    const inputElement = event.target as HTMLInputElement;

    const droppedText = event.dataTransfer?.getData('text') || '';
    const currentValue = inputElement.value;
    const selection = this.document.getSelection()?.toString() ?? '';

    // Compute the new value based on the drop and selection
    const newValue = this.computeNewValue(currentValue, droppedText, selection);

    if (!this.validateInput()(newValue, droppedText)) {
      event.preventDefault(); // Prevent invalid drop
    } else {
      this.lastValidValue = newValue; // Update the last valid value
    }
  }

  private computeNewValue(currentValue: string, inputData: string, selection:string): string | number {
    // Compute the new value as it would appear in the input
    const rawNewValue = selection === '' ? currentValue + inputData: currentValue.replace(selection, inputData);

    // Coerce the value to the correct type based on the input's type attribute
    return this.coerceValue(rawNewValue);
  }

  private coerceValue(rawValue: string): string | number {
    // Determine whether to parse as a number or keep as a string
    return !isNaN(Number(rawValue)) && rawValue.trim() !== '' ? Number(rawValue) : rawValue;
  }
  
}
