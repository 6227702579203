import "./shared/extensions/form.extension";
import "./shared/extensions/date.extension";

import { CommonModule } from '@angular/common';
import { Component,OnDestroy,OnInit,Signal, signal } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {NgIf} from "@angular/common";
import {
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective,
  ContainerComponent,
  ButtonDirective
} from '@coreui/angular-pro';
import {LoginComponent} from "./containers/home/login/login.component"
import { HeaderComponent } from './components/layout/header/header.component';
import { navItems } from './_nav';
import { Title } from '@angular/platform-browser';
import {FooterComponent} from './components/layout/footer/footer.component'
import { IconSetService } from '@coreui/icons-angular';
import { NgScrollbar } from 'ngx-scrollbar';
import { iconSubset } from '../assets/icons/icon-subset';
//import {SidebarComponent} from './components/layout/sidebar/sidebar.component'
import { AuthenticationService } from './services/authentication/authentication.service';
import { Subscription, finalize } from 'rxjs';
import { LocalStorageService } from './services/localStorage/local-storage.service';
import { ToastComponent } from './components/ui/toast/toast.component';
import { BreadcrumbComponent } from './components/layout/breadcrumb/breadcrumb.component';
import { environment } from '../environments/environment';
import { Roles } from "./shared/models/role.model";
import { IChildrenNavDataExtender } from "./shared/interfaces/navData";
import { Role } from "./shared/directives/c-render/c-render.model";
@Component({
  selector: 'sol-app',
  standalone: true,
  imports: [
    SidebarComponent,
    HeaderComponent,
    ContainerComponent,
    FooterComponent,
    ShadowOnScrollDirective,
    SidebarBrandComponent,
    SidebarFooterComponent,
    SidebarHeaderComponent,
    SidebarNavComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    NgScrollbar,
    NgIf,
    BreadcrumbComponent,
    LoginComponent,
    ButtonDirective,
    CommonModule, RouterModule,
    ToastComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  public navItems = navItems;
  public filteredNavItems:any; 
  title = 'SOL - Admin Panel';
  sidebarId:string = 'sidebar1'
  loggedinAs:string;
  isLoggedIn:boolean;
  isProdcution: boolean = environment.production
  subscriptions: Subscription[] = [];
  constructor( private titleService: Title,private iconSetService: IconSetService, private router: Router, 
    private authenticationService: AuthenticationService, private localStorage:LocalStorageService) {
    this.titleService.setTitle(this.title);
    this.iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {
    if(this.isProdcution){
      console.log = function() {}
      console.dir = function() {}
      console.table = function() {}
    }
    this.subscriptions.push(this.authenticationService.initializeAuthenticationState()
    .pipe(finalize(() => {
      console.log('Authentication state initialized');
    }))
    .subscribe({
      next: () => {},
      error: err => {
        console.error('Error initializing authentication state', err);
      }
    }));
     this.subscriptions.push(this.authenticationService.isLoggedin$.subscribe((e)=>{
         this.isLoggedIn = e
         this.filteredNavItems= this.filterMenuByRoles(navItems,this.authenticationService.assignedRoles);
     }));
    
     
    this.subscriptions.push(this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    }));
  }

  onScrollbarUpdate($event: any) {
    if ($event.verticalUsed) {
    console.log('verticalUsed', $event.verticalUsed);
    }
  }

  filterMenuByRoles(menu: any[], roles: string[]): any[] {
    return menu.map(item => {
      const filteredChildren = item.children.filter((child: IChildrenNavDataExtender) => {
        // Include child if it has no role or if its role is in the roles array
        return !child.role || (!Array.isArray(child.role) && roles.includes(Roles[child.role])) || (Array.isArray(child.role) && child.role.some((role: Role) => roles.includes(Roles[role])));
      });
  
      // Return item with filtered children, if any children are included
      return filteredChildren.length > 0 ? { ...item, children: filteredChildren } : null;
    }).filter(item => item !== null);
  }

  onLogout(){
    this.router.navigate(['/login']);
    this.authenticationService.logout();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(v=>v.unsubscribe);
  }
}
