<ng-container *ngIf="control">
  <c-input-group *ngIf="type === 'text'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      type="text"
      value="{{ value }}"
      [attr.list]="list()"
      autocomplete="off"
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      [formControl]="$any(control)"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
      (change)="updateValue($event)"
    />
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'textarea'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <textarea
      cFormControl
      value="{{ value }}"
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      [formControl]="$any(control)"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
      (change)="updateValue($event)"
      [rows]="options?.rows ? options!.rows! : 1"
    ></textarea>
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'file'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      type="file"
      #fileInput
      value="{{ value }}"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
      (change)="updateValue($event)"
      [accept]="acceptedFileTypes"
      [multiple]="multiFiles ? 'multiple' : ''"
    />
    <input
      type="text"
      [attr.list]="list()"
      autocomplete="off"
      cFormControl
      hidden
      [formControl]="$any(control)"
    />
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'price'" class="sol-input-group">
    <!-- <div cInputGroupText>$</div> -->
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { $ }
    </div>

    }
    <input
      cFormControl
      type="number"
      [step]="0.01"
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      [value]="displayNumber(value)"
      (change)="updateValue($event)"
      [solBlockInvalidInput]="handleBlockInputKeys('price')"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
      [formControl]="$any(control)"
    />
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'percentage'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      [step]="0.01"
      [min]="internalOptions.min === undefined ? null : internalOptions.min"
      [max]="internalOptions.max === undefined ? null : internalOptions.max"
      type="number"
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      [value]="displayNumber(value)"
      (change)="updateValue($event)"
      [solBlockInvalidInput]="handleBlockInputKeys('percentage')"
      [formControl]="$any(control)"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
    />
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'number'" class="sol-input-group">
    <!-- <div cInputGroupText>123</div> -->
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { 123 }
    </div>

    }
    <input
      cFormControl
      type="number"
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      [value]="displayNumber(value)"
      (change)="updateValue($event)"
      [solBlockInvalidInput]="handleBlockInputKeys('number')"
      [formControl]="$any(control)"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
    />
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'email'" class="sol-input-group">
    <!-- <div cInputGroupText>{{ "@" }}</div> -->
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else {
      {{ "@" }}
      }
    </div>

    }
    <input
      cFormControl
      type="text"
      [attr.list]="list()"
      autocomplete="off"
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      value="{{ value }}"
      (change)="updateValue($event)"
      [formControl]="$any(control)"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
    />
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'url'" class="sol-input-group">
    <!-- <div cInputGroupText></div> -->
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else {
      {{ "https://" }}
      }
    </div>

    }
    <input
      cFormControl
      type="text"
      [attr.list]="list()"
      autocomplete="off"
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      value="{{ value }}"
      (change)="updateValue($event)"
      [formControl]="$any(control)"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
    />
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'date'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      type="date"
      value="{{ value }}"
      (change)="updateValue($event)"
      [formControl]="$any(control)"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
    />
  </c-input-group>

  <c-input-group *ngIf="type === 'password'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      [type]="dynamicType()"
      value="{{ value }}"
      (change)="updateValue($event)"
      [formControl]="$any(control)"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
    />
    <span cInputGroupText>
      <div class="changeType" (click)="changeType()">
        <div class="icon" *ngIf="dynamicType() === 'text'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path
              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
            />
          </svg>
        </div>
        <div class="icon" *ngIf="dynamicType() === 'password'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path
              d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
            />
          </svg>
        </div>
      </div>
    </span>
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>

  <c-input-group *ngIf="type === 'numberedText'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      type="text"
      [attr.list]="list()"
      autocomplete="off"
      minlength="{{ options?.minLength }}"
      maxlength="{{ options?.maxLength }}"
      [value]="displayNumber(value)"
      (change)="updateValue($event)"
      [solBlockInvalidInput]="handleBlockInputKeys('numberedText')"
      [valid]="
        !(control.dirty || control.touched)
          ? undefined
          : (control.dirty || control.touched) && control.invalid
          ? false
          : true
      "
      [formControl]="$any(control)"
    />
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>
  <c-input-group *ngIf="type === 'route'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <a
      cFormControl
      class="form-control like-a disabled"
      [routerLink]="internalOptions.route"
      >{{ value }}</a
    >
    <ng-container *ngIf="control.errors">
      <ng-container *ngFor="let error of controlErrors">
        <c-form-feedback [valid]="false">{{
          displayError(error)
        }}</c-form-feedback>
      </ng-container>
    </ng-container>
  </c-input-group>
</ng-container>

<!-- without using a form control-->
<ng-container *ngIf="!control">
  <c-input-group *ngIf="type === 'text'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      type="text"
      [attr.list]="list()"
      autocomplete="off"
      [minLength]="options?.minLength"
      [maxLength]="options?.maxLength"
      value="{{ value }}"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
    />
  </c-input-group>
  <c-input-group *ngIf="type === 'file'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      type="file"
      #fileInput
      value="{{ value }}"
      (change)="updateValue($event)"
      [accept]="acceptedFileTypes"
      [multiple]="multiFiles ? 'multiple' : ''"
    />
  </c-input-group>

  <c-input-group *ngIf="type === 'price'" class="sol-input-group">
    <!-- <div cInputGroupText>$</div> -->
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { $ }
    </div>

    }
    <input
      cFormControl
      [step]="0.01"
      type="number"
      [minLength]="options?.minLength"
      [maxLength]="options?.maxLength"
      [value]="displayNumber(value)"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
      [solBlockInvalidInput]="handleBlockInputKeys('price')"
    />
  </c-input-group>

  <c-input-group *ngIf="type === 'percentage'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      [step]="0.01"
      [min]="internalOptions.min === undefined ? null : internalOptions.min"
      [max]="internalOptions.max === undefined ? null : internalOptions.max"
      type="number"
      [minLength]="options?.minLength"
      [maxLength]="options?.maxLength"
      [value]="displayNumber(value)"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
      [solBlockInvalidInput]="handleBlockInputKeys('percentage')"
    />
  </c-input-group>

  <c-input-group *ngIf="type === 'number'" class="sol-input-group">
    <!-- <div cInputGroupText>123</div> -->
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { 123 }
    </div>

    }
    <input
      cFormControl
      type="number"
      [minLength]="options?.minLength"
      [maxLength]="options?.maxLength"
      [value]="displayNumber(value)"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
      [solBlockInvalidInput]="handleBlockInputKeys('number')"
    />
  </c-input-group>

  <c-input-group *ngIf="type === 'email'" class="sol-input-group">
    <!-- <div cInputGroupText></div> -->
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else {
      {{ "@" }}
      }
    </div>

    }
    <input
      cFormControl
      type="text"
      [attr.list]="list()"
      autocomplete="off"
      [minLength]="options?.minLength"
      [maxLength]="options?.maxLength"
      value="{{ value }}"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
    />
  </c-input-group>

  <c-input-group *ngIf="type === 'url'" class="sol-input-group">
    <!-- <div cInputGroupText></div> -->
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else {
      {{ "https://" }}
      }
    </div>

    }
    <input
      cFormControl
      type="text"
      [attr.list]="list()"
      autocomplete="off"
      [minLength]="options?.minLength"
      [maxLength]="options?.maxLength"
      value="{{ value }}"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
    />
  </c-input-group>

  <c-input-group *ngIf="type === 'date'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      type="date"
      value="{{ value }}"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
    />
  </c-input-group>

  <c-input-group *ngIf="type === 'password'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      [type]="dynamicType()"
      [minLength]="options?.minLength"
      [maxLength]="options?.maxLength"
      value="{{ value }}"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
    />
    <span cInputGroupText>
      <div class="changeType" (click)="changeType()">
        <div class="icon" *ngIf="dynamicType() === 'text'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path
              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
            />
          </svg>
        </div>
        <div class="icon" *ngIf="dynamicType() === 'password'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path
              d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
            />
          </svg>
        </div>
      </div>
    </span>
  </c-input-group>
  <c-input-group *ngIf="type === 'numberedText'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <input
      cFormControl
      type="text"
      [attr.list]="list()"
      autocomplete="off"
      [minLength]="options?.minLength"
      [maxLength]="options?.maxLength"
      [value]="displayNumber(value)"
      [disabled]="options && options.disabled ? true : false"
      (change)="updateValue($event)"
      [solBlockInvalidInput]="handleBlockInputKeys('numberedText')"
    />
  </c-input-group>
  <c-input-group *ngIf="type === 'route'" class="sol-input-group">
    @if(internalOptions.withIcon) {
    <div cInputGroupText>
      @if(internalOptions.withIcon !== true && internalOptions.isIconPath){
      <img src="{{ internalOptions.withIcon }}" alt="icon" />
      } @else if (internalOptions.withIcon !== true &&
      !internalOptions.isIconPath) {
      {{ internalOptions.withIcon }}
      }@else { }
    </div>

    }
    <a
      class="form-control like-a disabled"
      [routerLink]="internalOptions.route"
      target="_blank"
      >{{ value }}</a
    >
  </c-input-group>
</ng-container>
