import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from "@angular/core";
import {
  ButtonDirective,
  LoadingButtonComponent,
  TooltipDirective,
} from "@coreui/angular-pro";
import { ButtonOptions, defaultButtonSettings } from "./button.model";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";
import { CRenderDirective } from "../../../shared/directives/c-render/c-render.directive";
import { SkipCRenderDirective } from "../../../shared/directives/c-render/c-render/skip-c-render.directive";

@Component({
  selector: "sol-button",
  standalone: true,
  imports: [
    RouterLink,
    TooltipDirective,
    ButtonDirective,
    CommonModule,
    LoadingButtonComponent,
  ],
  templateUrl: "./button.component.html",
  styleUrl: "./button.component.scss",
})
export class ButtonComponent implements OnInit, AfterViewInit {
  @Input() btnType: "primary" | "secondary" | "danger" | "success" | "warning" | "info" = "primary";
  @Input() options?: ButtonOptions;
  @Output() onclick = new EventEmitter<void>();
  defualtSettings = defaultButtonSettings;
  cRender: CRenderDirective | null = inject(SkipCRenderDirective, { optional: true }) !== null ? null : inject(CRenderDirective, { optional: true });
  

  constructor(private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    this.cRender?.addViewContainerRef(this.viewContainer);
  }

  ngAfterViewInit(): void {}

  handleClick() {
    this.onclick.emit();
  }

  get loading() {
    return this.options?.isLoading ?? this.defualtSettings.isLoading;
  }
}
