import { Injectable } from '@angular/core';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, of, switchMap, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppResponse } from '../../shared/models/api.model';
import { decodeToken } from "../../shared/interfaces/decodedToken"

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private apiUrl = environment.apiUrl;
  public isLoggedin: boolean = false;
  private isLoggedinSubject: BehaviorSubject<boolean>;

  get isLoggedin$(): Observable<boolean> {
    return this.isLoggedinSubject.asObservable();
  }

  get assignedRoles(): string[] {
    let token = this.localStorage.getAccessToken();
    if (token != undefined) {
      return token ? decodeToken(token as string)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] : [];
    }
    return [];
  }



  constructor(private localStorage: LocalStorageService, private http: HttpClient) {
    const token = this.localStorage.getAccessToken();
    this.isLoggedinSubject = new BehaviorSubject<boolean>(!!token);
    this.isLoggedin = !!token;
  }

  login(username: string, password: string): Observable<AppResponse<{ "token": string, "refreshToken": string }>> {
    return this.http.post<any>(`${this.apiUrl}/account/login`, { email: username, password: password }).pipe(
      tap(tokens => {
        this.localStorage.storeTokens({ token: tokens.data.token, refreshToken: tokens.data.refreshToken });
        this.isLoggedinSubject.next(true);
      })
    );
  }

  initializeAuthenticationState(): Observable<void> {
    const token = this.localStorage.getAccessToken();
    const refreshToken = this.localStorage.getRefreshToken();
    if (token && refreshToken) {
      return this.refreshToken().pipe(
        tap(tokens => {
          this.localStorage.storeTokens({ token: tokens.data.token, refreshToken: tokens.data.refreshToken });
          this.isLoggedinSubject.next(true);
        }),
        catchError(err => {
          console.error('Error refreshing token', err);
          return of();
        }),
        switchMap(() => of())
      );
    }
    return of();
  }

  refreshToken(): Observable<AppResponse<{ "token": string, "refreshToken": string }>> {
    const refreshToken = this.localStorage.getRefreshToken();
    const token = this.localStorage.getAccessToken();
    return this.http.post<AppResponse<{ "token": string, "refreshToken": string }>>(`${this.apiUrl}/account/refresh-token`, { token, refreshToken }).pipe(
      tap(tokens => {
        this.localStorage.storeTokens(tokens.data);
        this.isLoggedinSubject.next(true);
      }),
      catchError(error => {
        this.logout();
        return throwError(error);
      })
    );
  }

  logout() {
    this.localStorage.removeTokens();
    this.isLoggedinSubject.next(false);
    this.isLoggedin = false;
  }

  testAPI(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/Offline/warehouses`,).pipe(
      tap(tokens => {
        // this.localStorage.storeTokens({token:tokens.data.token, refreshToken:tokens.data.refreshToken});
        //  this.isLoggedinSubject.next(true);
      })
    );
  }

  hasRole(role: string): boolean {
    return this.assignedRoles.includes(role);
  }
  
}
