import { Injectable } from '@angular/core';
import {
  HttpInterceptorFn,
  HttpHandlerFn,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const BlobInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  return next(req).pipe(
    map((event) => {
      if (event instanceof HttpResponse) {
        const contentType = event.headers.get('Content-Type');

        // Handle Blob response with JSON Content-Type
        if (req.responseType === 'blob' && contentType?.includes('application/json')) {
          return new HttpResponse({
            body: handleJsonFromBlob(event.body as Blob),
            headers: event.headers,
            status: event.status,
            statusText: event.statusText,
            url: event.url || undefined,
          });
        }
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      // Handle HTTP errors
      if (error.error instanceof Blob && error.error.type === 'application/json') {
        return handleJsonError(error);
      }

      // Pass through other errors
      return throwError(() => error);
    })
  );
};

const handleJsonFromBlob = (blob: Blob): any => {
  const reader = new FileReader();
  return new Promise<any>((resolve, reject) => {
    reader.onload = () => {
      try {
        resolve(JSON.parse(reader.result as string));
      } catch (e) {
        reject(e);
      }
    };
    reader.onerror = (err) => reject(err);
    reader.readAsText(blob);
  });
};

const handleJsonError = (error: HttpErrorResponse): Observable<never> => {
  return new Observable((observer) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const json = JSON.parse(reader.result as string);
        observer.error(new HttpErrorResponse({ ...error, error: json, url: error.url ?? undefined }));
      } catch (e) {
        observer.error(error);
      }
    };
    reader.onerror = () => observer.error(error);
    reader.readAsText(error.error);
  });
};
